@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-custom-color {
    color: #a4845d; /* Couleur du texte */
  }
}

.font-playfair {
  font-family: 'Playfair Display', serif; /* Utilisation de la police */
}

body {
  background-color: #fcf6ed;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
